(function () {
        angular.module('informaApp')
            .component('timelineViewElement', {
                templateUrl: 'components/summary-view/summary-elements/elements/gantt-chart-element/timeline-view-element/template.html',
                controller: TimelineViewElement,
                bindings: {
                    data: '<',
                    settings: '<',
                    filter: '<'
                }
            });

        function TimelineViewElement(GanttChartMapper) {
            this.$onChanges = () => {
                this.chartSource = GanttChartMapper.mapAll(this.data);
            }
        }
    }
)();